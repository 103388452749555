
import { Component, Prop, Vue } from "vue-property-decorator";
import mButton from "@/components/mButton.vue";
import mDropZone from "@/components/mDropZone.vue";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

import AuthenticationService from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";

@Component
export default class UploadFileModal extends Vue {
  componentSize = ComponentSize;
  componentTheme = ComponentTheme;

  @Prop({ type: Boolean, default: () => true }) shouldDispatchSuccess: boolean;
  @Prop({ type: Function, default: () => () => {} }) onCancel: Function;
  @Prop({ type: Function, default: () => () => {} }) onConfirm: Function;
  @Prop({ type: Boolean, default: () => false }) display: boolean;

  displayName = "";
  uploadFileFormId = "uploadFileForm";

  onDropFile(file: any) {
    this.displayName = file.name;
  }


  public isValid() {
    const dropZone = this.$refs.dropZone as mDropZone;
    return dropZone ? dropZone.isValid() : false;
  }

  resetForm() {
    
    const dropZone = this.$refs.dropZone as mDropZone;
    if (dropZone) {
      dropZone.reset();
    }

    this.displayName = "";
  }

  mounted() {
    const currentUser = AuthenticationService.Instance.currentAuthenticatedUser;
    const prodHostname = "app.mantleblockchain.com";

    if (
      !currentUser.email.endsWith("@mantle.services") ||
      window.location.host === prodHostname
    ) {
    }
  }
}
