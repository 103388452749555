
import { Component, Vue } from "vue-property-decorator";
import AuthenticationService from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";
import { ComponentSize } from "@/enums/ComponentSize";
import { ComponentTheme } from "@/enums/ComponentTheme";
import axios from "axios";

@Component({})
export default class JoinClient extends Vue {
  firstName = "";
  lastName = "";
  email = "";
  clientName = "";
  password = "";
  passwordConfirmation = "";
  errors: string[] = [];
  loading = false;
  loadingForm = false;
  componentSize = ComponentSize;
  componentTheme = ComponentTheme;
  submittedForm = false;

  isValid(): boolean {
    this.errors = [];

    if (this.password !== this.passwordConfirmation) {
      this.errors.push("Passwords do not match");
    }

    return this.errors.length === 0;
  }

  async signUp() {
    this.loadingForm = true;

    try {
      if (this.isValid()) {
        await AuthenticationService.Instance.joinClient(
          this.firstName,
          this.lastName,
          this.email,
          this.$route.params.inviteId,
          this.password
        );
        this.$router.push({ path: "/home" });
      }
    } catch (e) {
      if (e.response) {
        this.errors =
          e.response.data instanceof Array
            ? e.response.data
            : [e.response.data];
      } else {
        this.errors = [e.message];
      }
    }

    this.loadingForm = false;
  }

  async loadInvite() {
    this.loading = true;

    try {
      const response = await axios.get(
        `${ConfigService.Instance.backEndUrl}/invitations/${this.$route.params.inviteId}`
      );
      this.email = response.data.newUserEmail;
      this.clientName = response.data.clientName;
    } catch (e) {
      if (e.response) {
        this.errors =
          e.response.data instanceof Array
            ? e.response.data
            : [e.response.data];
      } else {
        this.errors = [e.message];
      }
    }

    this.loading = false;
  }

  mounted() {
    AuthenticationService.Instance.localLogout();
    this.loadInvite();
  }
}
