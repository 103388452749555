
import { Component, Prop, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

@Component({})
export default class mButton extends Vue {
  @Prop({ type: String, default: () => "" }) title: string;
  @Prop({ type: Function, default: () => () => {} }) onClick: Function;
  @Prop({ type: Array, default: () => [] as any[] }) onClickParams: any[];
  @Prop({ type: String, default: () => ComponentTheme.Info })
  type: ComponentTheme;
  @Prop({ type: String, default: () => ComponentSize.Small })
  size: ComponentSize;
  @Prop({ type: Boolean, default: () => false }) outline: boolean;
  @Prop({ type: String, default: () => "" }) iconLeft: string;
  @Prop({ type: String, default: () => "" }) iconRight: string;
  @Prop({ type: Boolean, default: () => false }) disabled: boolean;
  @Prop({ type: Boolean, default: () => false }) hidden: boolean;
  @Prop({ type: Boolean, default: () => false }) minimal: boolean;
  @Prop({ type: Boolean, default: () => false }) fullWidth: boolean;
  @Prop({ type: Boolean, default: () => false }) pagination: boolean;

  @Prop({
    type: String,
    default: () => "This action is not possible at the moment"
  })
  disabledTooltip: string;

  handleOnClick() {
    if (!this.disabled) {
      this.onClick.apply(null, this.onClickParams);
    }
  }
}
