
import { Component, Prop, Vue } from "vue-property-decorator";
import mDataTable, {
  Field,
  FieldType,
  FloatType,
  OptionMenuItem
} from "@/components/mDataTable.vue";

import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

import ConfigService from "@/services/ConfigService";
import mRoleSelector from "@/components/mRoleSelector.vue";
import DataTableService from "@/services/DataTableService";
import AuthenticationService, {
  Roles,
  RoleType
} from "@/services/AuthenticationService";

@Component({
  data: function() {
    return {
      componentTheme: ComponentTheme,
      componentSize: ComponentSize
    };
  }
})
export default class UserList extends Vue {
  fields: Field[] = [
    {
      title: "",
      prop: "icon",
      width: 5,
      type: FieldType.HTML,
      render: DataTableService.renderIcon("fa-user"),
      float: FloatType.Center
    },
    {
      title: "Name",
      prop: "name",
      width: 40,
      type: FieldType.HTML,
      main: true,
      render: DataTableService.renderTwoLevelCell(
        (element: any) => `${element.firstName} ${element.lastName}`,
        (element: any) => element.email
      )
    },
    {
      title: "Roles",
      prop: "roles",
      width: 40,
      type: FieldType.HTML,
      render: DataTableService.renderMulti(
        (e: string) => e
      )
    }
  ];
  enabledMenuItems: OptionMenuItem[] = [
    { title: "Edit", icon: "fa-edit", onClick: this.onEditUserClick },
    { title: "Disable", icon: "fa-times", onClick: this.onDisableUserClick }
  ];
  disabledMenuItems: OptionMenuItem[] = [
    { title: "Enable", icon: "fa-check", onClick: this.onEnableUserClick }
  ];
  enabledEndpoint = "/users?isEnabled=true";
  disabledEndpoint = "/users?isEnabled=false";
  showDisableUser = false;
  showEnableUser = false;
  showDeleteUser = false;
  showEditUser = false;
  userIdToEnable = "";
  userNameToEnable = "";
  userIdToDisable = "";
  userNameToDisable = "";
  userIdToDelete = "";
  userNameToDelete = "";
  userIdToEdit = "";
  firstNameToEdit = "";
  lastNameToEdit = "";
  isAdmin = false;

  async mounted() {
    this.isAdmin = await AuthenticationService.Instance.isAdmin();
  }

  onEnableUserClick(element: any) {
    this.userIdToEnable = element.id;
    this.userNameToEnable = `${element.firstName} ${element.lastName}`;
    this.showEnableUser = true;
  }

  onDisableUserClick(element: any) {
    this.userIdToDisable = element.id;
    this.userNameToDisable = `${element.firstName} ${element.lastName}`;
    this.showDisableUser = true;
  }

  async onEditUserClick(element: any) {
    this.userIdToEdit = element.id;
    const userToEdit = await AuthenticationService.Instance.http.get(
      `${ConfigService.Instance.backEndUrl}/users/${this.userIdToEdit}`
    );
    this.firstNameToEdit = userToEdit.data.firstName;
    this.lastNameToEdit = userToEdit.data.lastName;
    this.showEditUser = true;
  }

  onCloseEnableModal() {
    this.showEnableUser = false;
    this.userIdToEnable = "";
    this.userNameToEnable = "";
  }

  onCloseDisableModal() {
    this.showDisableUser = false;
    this.userIdToDisable = "";
    this.userNameToDisable = "";
  }

  onCloseEditModal() {
    this.showEditUser = false;
    this.firstNameToEdit = "";
    this.lastNameToEdit = "";
    this.userIdToEdit = "";
    (this.$refs.roleSelector as mRoleSelector).reset();
  }

  async onEnableUserConfirm() {
    await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/users/${this.userIdToEnable}/enable`
    );
    this.onCloseEnableModal();
    (this.$refs.enabledUserTable as mDataTable).refresh();
    (this.$refs.disabledUserTable as mDataTable).refresh();
  }

  async onDisableUserConfirm() {
    await AuthenticationService.Instance.http.delete(
      `${ConfigService.Instance.backEndUrl}/users/${this.userIdToDisable}`
    );

    if (
      AuthenticationService.Instance.currentAuthenticatedUser.id ===
      this.userIdToDisable
    ) {
      await AuthenticationService.Instance.logout();
    }

    this.onCloseDisableModal();
    (this.$refs.enabledUserTable as mDataTable).refresh();
    (this.$refs.disabledUserTable as mDataTable).refresh();
  }

  async onEditUserConfirm() {
    await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/users/${this.userIdToEdit}`,
      {
        firstName: this.firstNameToEdit,
        lastName: this.lastNameToEdit,
        roles: (this.$refs.roleSelector as mRoleSelector).getRoles()
      }
    );

    if (
      AuthenticationService.Instance.currentAuthenticatedUser.id ===
      this.userIdToEdit
    ) {
      await AuthenticationService.Instance.logout();
    }

    this.onCloseEditModal();
    (this.$refs.enabledUserTable as mDataTable).refresh();
  }
}
