
import { Component, Vue } from "vue-property-decorator";
import EventService from "@/services/EventService";

@Component
export default class App extends Vue {
  mounted() {
    EventService.addToastListener(this.$refs.mToast);
  }

  destroyed() {
    EventService.removeToastListener();
  }
}
