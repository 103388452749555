
import { Component, Prop, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import { ProductSubMenus } from "@/enums/ProductSubMenus"

@Component
export default class mProductRow extends Vue {
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  windowWidth = 0;
  defaultPage = "/home";
  productSubMenus = ProductSubMenus;
  productSubMenu = "";

  @Prop({ type: String, default: () => null }) product: String;
  @Prop({ type: String, default: () => null }) link: Object;
  created() {
    this.productSubMenu = this.productSubMenus[this.$props.product]
    }
}

