
import { Component, Prop, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";

@Component
export default class mDeleteModal extends Vue {
  componentTheme = ComponentTheme;
  @Prop({ type: Boolean, default: () => false }) display: boolean;
  @Prop({ type: Function, default: () => async () => {} }) onConfirm: Function;
  @Prop({ type: Function, default: () => () => {} }) onCancel: Function;
  @Prop({ type: String, default: () => "" }) tooltip: string;
  @Prop({ type: String, default: () => "" }) description: string;
  @Prop({ type: String, default: () => null }) focusId: string;
}
