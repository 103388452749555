import { render, staticRenderFns } from "./PanelContainer.vue?vue&type=template&id=c05a92e6&scoped=true"
import script from "./PanelContainer.vue?vue&type=script&lang=ts"
export * from "./PanelContainer.vue?vue&type=script&lang=ts"
import style0 from "./PanelContainer.vue?vue&type=style&index=0&id=c05a92e6&prod&lang=less"
import style1 from "./PanelContainer.vue?vue&type=style&index=1&id=c05a92e6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c05a92e6",
  null
  
)

export default component.exports