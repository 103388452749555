
import { Component, Vue } from "vue-property-decorator";
import { Field, FieldType, OptionMenuItem } from "@/components/mDataTable.vue";
import DataTableService from "@/services/DataTableService";
import AuthenticationService from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";
import mDataTable from "@/components/mDataTable.vue"
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import UploadFileModal from "@/views/Receipt/components/UploadFileModal.vue";
import EventService from "@/services/EventService";
import { result } from "lodash";

@Component({
  components: {
    UploadFileModal
  }
})
export default class ReceiptList extends Vue {
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  shouldDispatchSuccess = true;
  isWriter = false;
  isAdmin = false;
  isReader = false;
  loading = false;
  file: any = null;
  showDownloadModal = false;
  showDownloadReceiptModal = false;
  showUploadFileModal = false;
  uploadFileFormId = "uploadFileForm";
  elements: any[] = [];
  fields: Field[] = [
    {
      title: "Id",
      prop: "id",
      width: 20,
      main: true,
      type: FieldType.HTML
    },
    {
      title: "Pointer",
      prop: "pointer",
      width: 30,
      type: FieldType.HTML,
      render: DataTableService.renderLink((element: any) => "https://testnet.wavesexplorer.com/address/"+element.pointer.substring(3), (element: any) => element.pointer)
    },
    {
      title: "Email",
      prop: "email",
      width: 20,
      type: FieldType.HTML
    },
    {
      title: "Creation Date",
      prop: "creationDate",
      width: 16,
      render: DataTableService.renderDate()
    }
  ];
  menuItems: OptionMenuItem[] = [
    {
      title: "Download",
      icon: "fa-download",
      onClick: this.onDownloadFileClick,
    },
    {
      title: "Download Receipt",
      icon: "fa-download",
      onClick: this.onDownloadReceiptClick,
    }

  ];

  async created() {
    this.isWriter = await AuthenticationService.Instance.isWriter();
    this.isAdmin = await AuthenticationService.Instance.isAdmin();
    this.isReader = await AuthenticationService.Instance.isReader();
  }
  onUploadFileClick() {
    this.showUploadFileModal = true;
  }
  onDownloadFileClick(element: any) {
  this.file = element;
  this.showDownloadModal = true;
  }
  onDownloadReceiptClick(element: any) {
  this.file = element;
  this.showDownloadReceiptModal = true;
  }

  onCloseDownloadFileModal() {
    this.file = null;
    this.showDownloadModal = false;
  }
  onCloseDownloadReceiptModal() {
  this.file = null;
  this.showDownloadModal = false;
  }
  onCloseUploadFileModal() {
    this.showUploadFileModal = false;
    (this.$refs.uploadFileModal as UploadFileModal).resetForm();
  }
  
  async onDownloadReceiptConfirm(){
    const url = window.URL.createObjectURL(new Blob([JSON.stringify(this.file)]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${this.file.pointer}.json`);
    document.body.appendChild(link);
    link.click();
    this.file = null;
    this.showDownloadReceiptModal = false;
  }

    async onDownloadFileConfirm() {
    const http = AuthenticationService.getAxiosInstanceWithAuthentication(
      ConfigService.Instance.backEndUrl
    );
    await http
      .get(
        `${ConfigService.Instance.backEndUrl}/gateway/receipt/${this.file.id}`,
        { responseType: "arraybuffer" }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.file.pointer}`);
        document.body.appendChild(link);
        link.click();
      });

    this.file = null;
    this.showDownloadModal = false;
  }
  endpoint = "/gateway/receipt";


  async onUploadFileConfirm() {
    var data = (<HTMLInputElement>document.getElementById("uploadFileInput")).files[0];
    var fileName = (<HTMLInputElement>document.getElementById("fileName")).value;
    if(data === undefined){
      EventService.createError("Please upload a file.");
      this.shouldDispatchSuccess = false
      return
    }
    if(fileName == ""){
      EventService.createError("Please enter a file name.");
      this.shouldDispatchSuccess = false
      return
    }
    this.shouldDispatchSuccess = true
    var fileName = (<HTMLInputElement>document.getElementById("fileName")).value;
    var blob = new Blob([data])
    var formData = new FormData()
    formData.append("file", blob, fileName)

    await AuthenticationService.Instance.http.post(
    `${ConfigService.Instance.backEndUrl}/gateway/receipt`, formData, {
      headers:{
        "Content-Type": "multipart/form-data"
        }
      }
    );
    this.onCloseUploadFileModal();
    (<HTMLInputElement>document.getElementById("fileName")).value = "";
    (this.$refs.receiptTable as mDataTable).refresh();
  }
}
