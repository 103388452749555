
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  default as AuthenticationService,
  RoleType, Roles
} from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";
import { Route } from "vue-router";
import _ from "lodash";
import { set } from "vue/types/umd";

export class MenuItem {
  id = "";
  label = "";
  icon = "";
  to = "";
  roles: RoleType[] = [];
  children: MenuItem[] = [];
}

@Component
export default class VerticalMenu extends Vue {
  menu: MenuItem[] = [];
  selectedParentId = "";
  selectedChildrenId = "";
  expandedParentId = "";
  hasCustomLogo = false;
  customLogoUrl = "";

  @Watch("$route")
  onRouteChange(to: Route) {
    this.getProcessedMenu(to.fullPath)
      .then(menu => {
        this.menu = menu;
      })
      .catch(console.error);
  }

  hideMenu() {
    const textMenu = document.getElementById("side-menu");

    if (textMenu) {
      if (textMenu.classList.contains("show")) {
        textMenu.classList.remove("show");
      }
    }
  }

  async beforeMount() {
    this.menu = await this.getProcessedMenu(this.$route.fullPath);
  }

  async getProcessedMenu(currentRoutePath: string): Promise<MenuItem[]> {
    const email =
      AuthenticationService.Instance.currentAuthenticatedUser["email"] || "";
    var isWriter = await AuthenticationService.Instance.isWriter();
    var isReader = await AuthenticationService.Instance.isReader();
    var isAdmin = await AuthenticationService.Instance.isAdmin();
    var isAnyRole = await AuthenticationService.Instance.isAnyRole();
    //const productMenuOptions = await AuthenticationService.Instance.getCurrentProductMenu();

    // const productMenu = productMenuOptions.map(menuOption => {
    //   switch (menuOption.type) {
    //     case ProductOptions.Bunker:
    //       return this.renderBunkerMenu(menuOption);
    //   }

    //   return {} as MenuItem;
    // });

    let mantleAdminMenu = [];
    if (email.endsWith("@mantle.services")) {
      mantleAdminMenu = [
        {
          id: "admin",
          label: "Administration",
          icon: "fa-cog",
          to: "/admin",
          children: [
            {
              id: "clients",
              label: "Clients",
              icon: "fa-users",
              to: "/clients"
            } as MenuItem
          ]
        } as MenuItem
      ];
    }
    let settingsMenu = [];
      settingsMenu.push({
        id: "settings",
        label: "Settings",
        icon: "fa-sliders-h",
        to: "/settings",
        children: [
          {
            id: "settingsserviceaccountlist",
            label: "Service Account",
            to: "/serviceaccount"
          },
          {
            id: "settingsapikey",
            label: "My API Key",
            to: "/apikey"
          }
        ]
      } as MenuItem);

      if(isAdmin){
        settingsMenu[0].children.push(          {
            id: "userlist",
            label: "Users",
            to: "/users",
          },)
      }
  
    let billingMenu = [];
    if(isAdmin){
      billingMenu = [{
        id: "billing",
        label: "Billing",
        icon: "fa-money-bill-alt",
        to: "/billing",
        children: [
          {
            id: "allbills",
            label: "All Bills",
            to: "/all",
          },
                  {
            id: "currentbill",
            label: "Curent Bill",
            to: "/current",
          }
        ]
      } as MenuItem]
    
      settingsMenu[0].children.push(
        {
            id: "settingsapikeylist",
            label: "API Keys",
            to: "/apikeys"
          } as MenuItem
      );
       settingsMenu[0].children.push(
       {
          id: "settingsinvitelist",
          label: "Invites",
          to: "/invites"
        } as MenuItem
      );
    }
    let homeMenu =[{
        id: "home",
        label: "Home",
        icon: "fa-home",
        to: "/home"
      } as MenuItem
      ];
  let gatewayMenu = []
    if(isReader || isWriter || isAdmin)
    {
      gatewayMenu.push({
        id: "Gateway",
        label: "Gateway",
        icon: "fa-copy",
        to: "/gateway",
        children: [
          {
            id: "Receipts",
            label: "Receipts",
            to: "/receipt",
          }
        ]
      } as MenuItem)
    }

    let menu = [
      ...homeMenu,
      ...gatewayMenu,
      ...billingMenu,
      ...settingsMenu,
      ...mantleAdminMenu
    ];

    const matches = /^(\/[A-Za-z]+(?:\/[a-f\d]{24}|))(\/[A-Za-z]+|)([^]*)/.exec(
      currentRoutePath
    );
    const basePath = matches[1];
    const subPath = matches[2];

    menu.forEach(menuItem => {
      if (menuItem.to === basePath) {
        this.selectedParentId = menuItem.id;
        this.expandedParentId = menuItem.id;
        this.selectedChildrenId = "";
        if (subPath && menuItem.children) {
          menuItem.children.forEach(childrenItem => {
            if ((childrenItem.to as any) instanceof Array) {
              if (childrenItem.to.indexOf(subPath) != -1) {
                this.selectedChildrenId = `${menuItem.id}-${childrenItem.id}`;
              }
            } else if (childrenItem.to === subPath) {
              this.selectedChildrenId = `${menuItem.id}-${childrenItem.id}`;
            }
          });
        }
      }
    });

    // Remove menu which the user doesn't have required roles
    const userRoles = await AuthenticationService.Instance.getUserRoles();
    const filterMenuItemPredicate = (menuItem: MenuItem) => {
      if (userRoles == null) {
        return false;
      }
      if (menuItem.roles) {
        return menuItem.roles.some(r => userRoles.indexOf(r) !== -1);
      }
      return true;
    };
    if (userRoles!=null && userRoles.indexOf(RoleType.Admin) === -1) {
      menu = menu.filter(menuItem => {
        if (menuItem.children) {
          menuItem.children = menuItem.children.filter(filterMenuItemPredicate);
        }
        return filterMenuItemPredicate(menuItem);
      });
    }

    return menu;
  }

  toggleChildrenAndShow(parentId: any) {
    const textMenu = document.getElementById("side-menu");

    if (textMenu) {
      if (!textMenu.classList.contains("show")) {
        textMenu.classList.add("show");
      }
    }

    this.expandedParentId = parentId;
  }

  toggleChildren(parentId: any) {
    this.expandedParentId = this.expandedParentId === parentId ? "" : parentId;
  }
}
