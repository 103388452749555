
import { Component, Vue } from "vue-property-decorator";
import AuthenticationService from "@/services/AuthenticationService";
import mToast from "@/components/mToast.vue";
import EventService from "@/services/EventService";

@Component
export default class Login extends Vue {
  async mounted() {
    if (AuthenticationService.Instance.isUserAuthenticated()) {
      this.$router.push({ path: "/" });
      // TODO: add logic to redirect to the proper section of the app after loging in - Issue #474
    } else {
      (await AuthenticationService.Instance.getLock()).show();
      await AuthenticationService.Instance.handleLockAuthentication();
    }
  }
}
