
import { Component, Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { Roles, RoleType } from "@/services/AuthenticationService";

@Component({
  components: {
    Multiselect
  }
})
export default class mRoleSelector extends Vue {
  rolesModel: RoleOption[] = [];
  roles: RoleOption[] = this.mapEnumToRoleOptions(
    Object.keys(Roles) as string[]
  );

  public initializeRoles(roles: string[]) {
    this.rolesModel = this.mapStringsToRoleOptions(roles);
  }

  public getRoles(): number[] {
    return this.rolesModel.map(r => parseInt(r.value));
  }

  public reset() {
    this.rolesModel = [];
  }

  private mapStringsToRoleOptions(roles: string[]) {
    return roles.map(key => {
      return {
        value: RoleType[key].toString(),
        name: Roles[RoleType[key]]
      } as RoleOption;
    });
  }

  private mapEnumToRoleOptions(roles: string[]) {
    return roles.map(key => {
      return { value: key, name: Roles[key] } as RoleOption;
    });
  }
}

interface RoleOption {
  value: string;
  name: string;
}
