import { Route, RouteConfig } from "vue-router";
import ReceiptList from "@/views/Receipt/ReceiptList.vue";
import ProductContainer from "@/layout/ProductContainer.vue";

export const GatewayRoutes: RouteConfig[] = [
  {
    path: "/gateway/",
    name: "receipt-container",
    component: ProductContainer,
    redirect: "/gateway",
    meta: {
      title: "Gateway",
      isNavigable: false
    },
    children: [
      {
        path: "/gateway/receipt",
        name: "receipt-dashboard",
        component: ReceiptList,
        meta: {
          title: "dashboard"
        }
      }
    ]
  }
];
