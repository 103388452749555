import Vue from "vue";
import Router from "vue-router";

import AuthenticationService, {Roles, RoleType} from "@/services/AuthenticationService";
import { BaseRoutes } from "@/routes/base-routes";
import { PanelRoutes } from "@/routes/panel-routes";
import EventService from "@/services/EventService";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [...BaseRoutes, ...PanelRoutes]
});

// TODO: Will need to turn this into a middleware eventually
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.role) {
    var roles = await AuthenticationService.Instance.getUserRoles();
    var isAdmin = await AuthenticationService.Instance.isMantleAdmin();
    if (!AuthenticationService.Instance.currentAuthenticatedUser) {
      console.warn("User not logged in, redirecting to login page.");
      next("/login");
    } else {
      if(roles != null && roles.includes(to.meta.role) || isAdmin){
        next();
        return;
      }
      EventService.dispatchError("You don't have access to this ressource");
      router.push("/home")
      return;
    }
  } else {
    next();
  }
});

export default router;
