import { Route } from "vue-router";

export default class ConfigService {
  private static _instance: ConfigService;
  public backEndUrl = "";
  public adminAppUrl = "";
  public websiteUrl = "https://mantleblockchain.com";

  public static get Instance() {
    return this._instance || (this._instance = new ConfigService());
  }

  private constructor() {
    const hostName = window.location.hostname;
    const genericRegex = /^([a-zA-Z0-9_]+\.)(app.mantle.technology|mantletechnology.com|mantleblockchain.com).*/;
    const matches = genericRegex.exec(hostName);

    if (matches !== null && matches.length == 3) {
      const stage = matches[1];
      const domain = matches[2];

      if (stage) {
        if (stage === "app.") {
          this.backEndUrl = `https://api.${domain}`;
          this.adminAppUrl = `https://app.${domain}`;
        } else {
          this.adminAppUrl = `https://${stage}${domain}`;
          var newDomain = domain.replace('app.', "")
          this.backEndUrl = `https://${stage}api.${newDomain}`;
        }
      }
    } else {
      const protocol = window.location.protocol;
      this.backEndUrl = `${protocol}//${hostName}:49173`;
      this.adminAppUrl = `${protocol}//${hostName}:8081`;
    }
  }
}
