
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";
import { ComponentTheme } from "@/enums/ComponentTheme";

@Component
export default class mToast extends Vue {
  messages: InnerToastEvent[] = [];

  iconMap = {
    [ComponentTheme.Danger]: "fa-exclamation-circle",
    [ComponentTheme.Warning]: "fa-question-circle",
    [ComponentTheme.Hazard]: "fa-exclamation-triangle",
    [ComponentTheme.Confirmation]: "fa-check-circle",
    [ComponentTheme.Info]: "fa-info-circle"
  };

  public showMessage(event: ToastEvent) {
    const eventKey = new Date().getTime();
    this.messages.push({
      id: eventKey,
      theme: event.theme,
      message: event.message
    } as InnerToastEvent);
    this.$nextTick(function() {
      window.setTimeout(() => {
        this.clearMessage(eventKey);
      }, 5000);
    });
  }

  clearMessage(eventKey: number) {
    this.messages = _.filter(this.messages, u => u.id !== eventKey);
  }
}

export interface ToastEvent {
  message: string;
  theme: ComponentTheme;
}

interface InnerToastEvent {
  id: number;
  message: string;
  theme: ComponentTheme;
}
