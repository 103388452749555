
import { Component, Prop, Vue } from "vue-property-decorator";
import mDataTable, {
  Field,
  FieldType,
  FloatType,
  OptionMenuItem
} from "@/components/mDataTable.vue";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import DataTableService from "@/services/DataTableService";
import ConfigService from "@/services/ConfigService";
import AuthenticationService, {Roles} from "@/services/AuthenticationService";
import mUserSelector from "@/components/mUserSelector.vue";

@Component
export default class ServiceAccountApiKeyList extends Vue {
  apiKeyFields: Field[] = [
    {
      title: "",
      prop: "icon",
      width: 10,
      type: FieldType.HTML,
      render: DataTableService.renderIcon("fa-key"),
      float: FloatType.Center
    },
    { title: "Name", prop: "name", width: 65, main: true },
    {
      title: "Creation Date",
      prop: "creationDate",
      width: 25,
      render: DataTableService.renderDate()
    }
  ];
  apiKeyMenuItems: OptionMenuItem[] = [
    { title: "Delete", icon: "fa-trash", onClick: this.onDeleteApiKeyClick }
  ];
  userFields: Field[] = [
    {
      title: "",
      prop: "icon",
      width: 5,
      type: FieldType.HTML,
      render: DataTableService.renderIcon("fa-user"),
      float: FloatType.Center
    },
    {
      title: " Owner Users",
      prop: "name",
      width: 40,
      type: FieldType.HTML,
      main: true,
      render: DataTableService.renderTwoLevelCell(
        (element: any) => `${element.firstName} ${element.lastName}`,
        (element: any) => element.email
      )
    },
    {
      title: "Roles",
      prop: "roles",
      width: 40,
      type: FieldType.HTML,
      render: DataTableService.renderMulti(
        (e: string) => e
      )
    }
  ];
    ownerMenuItems: OptionMenuItem[] = [
    { title: "Delete", icon: "fa-check", onClick: this.onDeleteOwner }
  ];
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  apiKeysEndpoint = "";
  ownerEndpoint = "";
  showCreateModal = false;
  showDeleteModal = false;
  apiKeyToDelete = "";
  keyNameToDelete = "";
  keyName = "";
  serviceAccountId = ""
  ownerToDelete = [];
  showOwnerDeleteModal = false;
  showAddOwnerModal = false;
  ownerToAdd = "";
  showApiKeyModal = false;
  apikey = "";
  serviceAccountEmail = "";
  isAdmin = "";

  async mounted() {
    this.serviceAccountId = this.$route.params.id
    this.apiKeysEndpoint = `/apikeys/serviceaccount/${this.serviceAccountId}`
    this.ownerEndpoint = `/serviceaccounts/owner/${this.serviceAccountId}`
    var res = await AuthenticationService.Instance.http.get(`${ConfigService.Instance.backEndUrl}/serviceaccounts/${this.serviceAccountId}`)
    this.serviceAccountEmail = res.data.email
    this.isAdmin = await AuthenticationService.Instance.isAdmin();
  }
  
  menuUniqueIdFunction(element: any) {
    return element.id;
  }

  onCreateApiKeyClick() {
    this.showCreateModal = true;
  }

  onDeleteApiKeyClick(element: any) {
    this.apiKeyToDelete = element.id;
    this.showDeleteModal = true;
  }

  onCloseCreateModal() {
    this.showCreateModal = false;
    this.keyName = "";
  }

  onCloseAddOwnerModal() {
    this.showAddOwnerModal = false;
  }

  onAddOwnerClick() {
    this.showAddOwnerModal = true;
  }

  onDeleteOwner(element: any) {
    this.ownerToDelete.push(element.id);
    this.showOwnerDeleteModal = true;
  }

  onCloseDeleteOwnerModal() {
    this.ownerToDelete = [];
    this.showOwnerDeleteModal = false;
  }
  onCloseApiKeyModal() {
    this.showApiKeyModal = false;
    this.apikey = "";
  }

  onCloseDeleteModal() {
    this.showDeleteModal = false;
  }

  async onConfirmApiKeyModal() {
    await navigator.clipboard.writeText(this.apikey);
    this.onCloseApiKeyModal()
  }

  async onDeleteApiKeyConfirm() {
    const serviceaccountId = this.$route.params.id;
    await AuthenticationService.Instance.http.delete(
      `${ConfigService.Instance.backEndUrl}/apikeys/serviceaccount/${serviceaccountId}/${this.apiKeyToDelete}`
    );
    this.onCloseDeleteModal();
    (this.$refs.apiKeyTable as mDataTable).refresh();
  }

  async onCreateApiKeyConfirm() {
    const serviceaccountId = this.$route.params.id;
    var res = await AuthenticationService.Instance.http.post(
      `${ConfigService.Instance.backEndUrl}/apikeys/serviceaccount/${serviceaccountId}`,
      {
        name: (<HTMLInputElement>document.getElementById("keyName")).value
      }
    );
    this.apikey = res.data;
    this.onCloseCreateModal();
    this.showApiKeyModal = true;
    (this.$refs.apiKeyTable as mDataTable).refresh();
  }

  async onDeleteOwnerConfirm() {
      await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/serviceaccounts/${this.serviceAccountId}/removeowner`,
      { 
        userIds: this.ownerToDelete
      }
    );
    this.onCloseDeleteOwnerModal();
    (this.$refs.ownerTable as mDataTable).refresh();
  }

  async onConfirmAddOwnerModal() {
    const users = (this.$refs.userSelector as mUserSelector).getSelectedUsersIds();
    await AuthenticationService.Instance.http.put(
    `${ConfigService.Instance.backEndUrl}/serviceaccounts/${this.serviceAccountId}/addowner`,
    { 
      userIds: users
    }
  );
  this.onCloseAddOwnerModal();
  (this.$refs.userSelector as mUserSelector).reset();
  (this.$refs.ownerTable as mDataTable).refresh();
    
  }
}
