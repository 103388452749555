import { Route, RouteConfig } from "vue-router";
import InviteList from "@/views/Settings/InviteList.vue";
import UserList from "@/views/Settings/UserList.vue";
import ApiKeyPage from "@/views/Settings/ApiKeyPage.vue";
import ApiKeyList from "@/views/Settings/ApiKeyList.vue";
import ProductContainer from "@/layout/ProductContainer.vue";
import ServiceAccountList from "@/views/Settings/ServiceAccountList.vue";
import ServiceAccountApiKeysList from "@/views/Settings/ServiceAccountApiKeysList.vue"

export const SettingsRoutes: RouteConfig[] = [
  {
    path: "/settings",
    name: "setttings-container",
    component: ProductContainer,
    redirect: "/settings/customization",
    meta: {
      title: "Settings",
      isNavigable: false
    },
    children: [
      {
        path: "/settings/apikey",
        name: "settings-apikey",
        component: ApiKeyPage,
        meta: {
          title: "My API Key"
        }
      },
      {
        path: "/settings/apikeys",
        name: "settings-apikey-list",
        component: ApiKeyList,
        meta: {
          role: "Admin",
          title: "API Keys"
        }
      },
      {
        path: "/settings/serviceaccount/:id",
        name: "service-account-api-keys",
        component: ServiceAccountApiKeysList,
        meta: {
          title: "Service Account Api Keys"
        }
      },
      {
        path: "/settings/invites",
        name: "settings-invite-list",
        component: InviteList,
        meta: {
          role: "Admin",
          title: "Invite List"
        }
      },
      {
        path: "/settings/users",
        name: "settings-user-list",
        component: UserList,
        meta: {
          role: "Admin",
          title: "User List"
        }
      },
      {
        path: "/settings/serviceAccount",
        name: "settings-service-account",
        component: ServiceAccountList,
        meta: {
          title: "My Service Accounts"
        }
      }
    ]
  }
];
