
import { Component, Vue } from "vue-property-decorator";
import mDataTable, { Field, FieldType, FloatType, OptionMenuItem } from "@/components/mDataTable.vue";
import DataTableService from "@/services/DataTableService";
import AuthenticationService from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";

@Component({})
export default class BillingList extends Vue {
  elements: any[] = [];
  billToPay = "";
  fields: Field[] = [
    {
      title: "Id",
      prop: "id",
      width: 20,
      main: true,
      type: FieldType.HTML
    },
    {
      title: "Credit Used",
      prop: "creditUsed",
      width: 15,
      type: FieldType.HTML,
      float: FloatType.Center,
      render: DataTableService.renderShortInteger()
    },
    {
      title: "Credit Bank",
      prop: "creditBank",
      width: 15,
      render: DataTableService.renderShortInteger()
    },
    {
      title: "Closed",
      prop: "closed",
      width: 10,
      render: DataTableService.renderBool()
    },
    {
      title: "Creation Date",
      prop: "creationDate",
      width: 16,
      render: DataTableService.renderDate()
    },
    {
      title: "End Date",
      prop: "endDate",
      width: 10,
      render: DataTableService.renderDate()
    },
    {
      title: "Paid",
      prop: "paid",
      width: 10,
      render: DataTableService.renderBool()
    }
  ];
  menuOptions: OptionMenuItem[] = [
    { title: "Pay", icon: "fa-edit", onClick: this.payBill },
  ];
  allBillsEndPoint = "/billing/all";
  unpaidBillsEndPoint = "/billing/unpaid"

 async payBill(element: any) {
    this.billToPay = element.id;
    const bill = await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/billing/${this.billToPay}/pay`
    );
    (this.$refs.unpaidBillsTable as mDataTable).refresh();
  }
}
