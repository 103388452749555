
import { Component, Prop, Vue } from "vue-property-decorator";
import numeral from "numeral";

import { ComponentTheme } from "@/enums/ComponentTheme";

@Component
export default class mStatisticCard extends Vue {
  @Prop({ type: String, default: () => "" }) statisticTitle: string;
  @Prop({ type: Number, default: () => 0 }) statisticValue: number;
  @Prop({ type: String, default: () => "" }) statisticUnit: string;
  @Prop({ type: String, default: () => "chart-line" }) icon: string;
  @Prop({ type: String, default: () => ComponentTheme.Confirmation })
  theme: ComponentTheme;
  @Prop({ type: Boolean, default: () => false }) outline: boolean;
  numeral = numeral;
}
