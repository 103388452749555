
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class mCreateModal extends Vue {
  @Prop({ type: Boolean, default: () => false }) display: boolean;
  @Prop({ type: Function, default: () => async () => {} }) onConfirm: Function;
  @Prop({ type: Function, default: () => () => {} }) onCancel: Function;
  @Prop({ type: String, default: () => "" }) tooltip: string;
  @Prop({ type: String, default: () => "" }) description: string;
  @Prop({ type: String, default: () => null }) focusId: string;
  @Prop({ type: Function, default: () => false }) preventKeyShortcuts: Function;
}
