import { RouteConfig } from "vue-router";
import PanelContainer from "@/views/Panel/PanelContainer.vue";
import BillingList from "@/views/Billing/BillingList.vue";
import { GatewayRoutes } from "@/routes/gateway-routes";
import { AdminRoutes } from "@/routes/admin-routes";

import CurrentBill from "@/views/Billing/CurrentBill.vue";
import { SettingsRoutes } from "@/routes/settings-routes";
import HomePage from "@/views/Home/HomePage.vue";
import { BillingRoutes } from "./billing-routes";

export const PanelRoutes: RouteConfig[] = [
  {
    path: "/",
    name: "panel",
    component: PanelContainer,
    redirect: "/home",
    children: [ 
     {
      path: "/home",
      name: "home",
      component: HomePage,
      meta: {
        title: "Home"
      }
    },
      ...SettingsRoutes,
      ...GatewayRoutes,
      ...BillingRoutes,
      ...AdminRoutes,
    ]
  },
];
