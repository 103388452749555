
import { Component, Vue } from "vue-property-decorator";
import AuthenticationService from "@/services/AuthenticationService";
import EventService from "@/services/EventService";

@Component
export default class Callback extends Vue {
  async mounted() {
    await AuthenticationService.Instance.handleLockAuthentication();
    await AuthenticationService.Instance.handleLockAuthenticationErrors();

    if (!this.$route.hash) {
      this.$router.push({ path: "/home" });
    }
  }
}
