
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class mTooltip extends Vue {
  @Prop({ type: Boolean, default: () => false }) enabled: boolean;
  @Prop({ type: Boolean, default: () => false }) pagination: boolean;
  @Prop({ type: String, default: () => "" }) content: string;
  top = 0;
  style: any = {};

  mounted() {
    if (this.enabled && !this.pagination) {
      this.style = this.getTop();
    }
  }

  getTop() {
    // TODO : Find a better way to wait for the child to be rendered before checking its height
    const childOffset =
      ((this.$refs.child as HTMLElement).offsetHeight || 45) + 5;
    const tooltipOffset =
      ((this.$refs.tooltip as HTMLElement).offsetHeight || 45) + 5;

    if (
      tooltipOffset >
      (this.$refs.child as HTMLElement).getBoundingClientRect().top
    ) {
      return { top: `${childOffset}px` };
    } else {
      return { bottom: `${childOffset}px` };
    }
  }
}
