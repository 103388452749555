
import { Component, Prop, Vue } from "vue-property-decorator";
import mDataTable, {
  Field,
  FieldType,
  FloatType,
  OptionMenuItem
} from "@/components/mDataTable.vue";

import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

import ConfigService from "@/services/ConfigService";
import mRoleSelector from "@/components/mRoleSelector.vue";
import DataTableService from "@/services/DataTableService";
import AuthenticationService, {
  Roles,
  RoleType
} from "@/services/AuthenticationService";
import mUserSelector from "@/components/mUserSelector.vue";

@Component({
  data: function() {
    return {
      componentTheme: ComponentTheme,
      componentSize: ComponentSize
    };
  }
})
export default class ServiceAccountList extends Vue {
  fields: Field[] = [
    {
      title: "",
      prop: "icon",
      width: 5,
      type: FieldType.HTML,
      render: DataTableService.renderIcon("fa-user"),
      float: FloatType.Center
    },
    {
      title: "Name",
      prop: "email",
      width: 40,
      type: FieldType.HTML,
      main: true
    },
    {
      title: "Roles",
      prop: "roles",
      width: 40,
      type: FieldType.HTML,
      render: DataTableService.renderMulti(
        (e: string) => e
      )
    }
  ];

  enabledMenuItem: OptionMenuItem[] = [
    { title: "Disable", icon: "fa-trash", onClick: this.onDisableClick }
  ];
  disabledMenuItem: OptionMenuItem[] = [
    { title: "Enable", icon: "fa-trash", onClick: this.onEnableClick }
  ];
  enabledEndpoint = "";
  disabledEndpoint = "";
  showDisable = false;
  userIdToDisable = "";
  userIdToEnable = "";
  showEnable = false
  serviceAccountEmailToDisable = "";
  serviceAccountEmailToEnable = "";
  showCreateServiceAccountModal = false;
  isAdmin = false
  
  async created() {
    this.isAdmin = await AuthenticationService.Instance.isAdmin();
    this.enabledEndpoint = this.isAdmin?"/serviceaccounts?isEnabled=true": "/serviceaccounts/owned?isEnabled=true";
    this.disabledEndpoint = this.isAdmin?"/serviceaccounts?isEnabled=false": "/serviceaccounts/owned?isEnabled=false";

  }

  onCloseCreateServiceAccountModal() {
    this.showCreateServiceAccountModal = false;
    (this.$refs.roleSelector as mRoleSelector).reset();
    (this.$refs.userSelector as mUserSelector).reset();
    (<HTMLInputElement>document.getElementById("keyName")).value = "";

  }

  onClickCreateServiceAccount() {
    this.showCreateServiceAccountModal = true;
  }
  onDisableClick(element: any) {
    this.userIdToDisable = element.id;
    this.serviceAccountEmailToDisable = element.email;
    this.showDisable = true;
  }

  onCloseDisableModal() {
    this.showDisable = false;
    this.userIdToDisable = "";
  }

  onCloseEnableModal() {
    this.showEnable = false;
    this.userIdToEnable = "";
    this.serviceAccountEmailToEnable = "";
  }
  onEnableClick(element: any) {
    this.userIdToEnable = element.id;
    this.serviceAccountEmailToEnable = element.email;
    this.showEnable = true;
  }

  onRowClick(element: any) {
    this.$router.push({
      path: `serviceaccount/${element.id}`
    });
  }

  async onConfirCreateServiceAccountModal() {
    const name = (<HTMLInputElement>document.getElementById("keyName")).value
    const roles = (this.$refs.roleSelector as mRoleSelector).getRoles();
    const owner = (this.$refs.userSelector as mUserSelector).getSelectedUsersIds();

    await AuthenticationService.Instance.http.post(
      `${ConfigService.Instance.backEndUrl}/serviceaccounts`,
      {
        roles: roles,
        owners: owner,
        name: name
      }
    );

    this.onCloseCreateServiceAccountModal();
    (this.$refs.enabledServiceAccountTable as mDataTable).refresh();
  }

  async onDisableConfirm() {
    await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/serviceaccounts/${this.userIdToDisable}/disable`
    );

    this.onCloseDisableModal();
    (this.$refs.enabledServiceAccountTable as mDataTable).refresh();
    (this.$refs.disabledServiceAccountTable as mDataTable).refresh();

  }

  async onEnableUserConfirm() {
    await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/serviceaccounts/${this.userIdToEnable}/enable`
    );
    this.onCloseEnableModal();
    (this.$refs.enabledServiceAccountTable as mDataTable).refresh();
    (this.$refs.disabledServiceAccountTable as mDataTable).refresh();
  }
}
