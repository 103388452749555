
import { Component, Prop, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import Multiselect from "vue-multiselect";
import mRoleSelector from "@/components/mRoleSelector.vue"

@Component({
  components: {
    Multiselect
  }
})
export default class InviteModal extends Vue {
  componentTheme = ComponentTheme;

  @Prop({ type: Boolean, default: () => false }) display: boolean;
  @Prop({ type: Function, default: () => async () => {} }) onConfirm: Function;
  @Prop({ type: Function, default: () => () => {} }) onCancel: Function;

  emailToInvite = "";

  onCancelClick() {
    this.reset();
    this.onCancel();
  }
  
  public getSelectedRoles(): number[] {
    return (this.$refs.roleSelector as mRoleSelector).getRoles();
  }


  public reset() {
    this.emailToInvite = "";
  }

  public getEmail() {
    return this.emailToInvite;
  }
}
