import moment from "moment";
import crypto from "crypto";

export default class UtilsService {
  static readonly HASH_ALGORITHM = "sha256";

  static sleep(time: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  static renderDate(value: any, dateFormat = "MMM Do, YYYY HH:mm") {
    return value != null && value !== 0
      ? moment(value).format(dateFormat)
      : "N/A";
  }

  static generateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }

  static generateHash(value: any): string {
    return crypto
      .createHash(this.HASH_ALGORITHM)
      .update(value)
      .digest("hex");
  }
}
