import { render, staticRenderFns } from "./JoinClient.vue?vue&type=template&id=49d592fd&scoped=true"
import script from "./JoinClient.vue?vue&type=script&lang=ts"
export * from "./JoinClient.vue?vue&type=script&lang=ts"
import style0 from "./JoinClient.vue?vue&type=style&index=0&id=49d592fd&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d592fd",
  null
  
)

export default component.exports