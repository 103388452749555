
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class PageNotFound extends Vue {
  errors: string[] = [];

  backToPreviousPage() {
    this.$router.go(-1);
  }
}
