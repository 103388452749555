
import { Component, Prop, Vue } from "vue-property-decorator";
import { ProductOptions } from "@/enums/ProductOptions";
import { ProductIcons } from "@/enums/ProductIcons";
import { ProductColors } from "@/enums/ProductColors";
import { ProductSubMenus } from "@/enums/ProductSubMenus"

@Component
export default class mProductCard extends Vue {
  productIcons = ProductIcons;
  productColors = ProductColors;
  productSubMenu = "";
  @Prop({ type: String, default: () => [] }) product: string;

  mounted() {
    this.productSubMenu = ProductSubMenus[this.$props.product];
  }
}
