
import { Component, Prop, Vue } from "vue-property-decorator";
import mButton from "./mButton.vue";

@Component
export default class mModal extends Vue {
  @Prop({ type: Boolean, default: () => false }) display: boolean;
  @Prop({ type: String, default: () => "" }) title: string;
  @Prop({ type: String, default: () => "" }) tooltip: string;
  @Prop({ type: Function, default: () => () => {} }) onBackDropClick: Function;
  @Prop({ type: Array, default: () => [] as any[] }) errors: string[];
  @Prop({ type: Array, default: () => [] as any[] }) results: string[];
  @Prop({ type: Boolean, default: () => false }) loading: boolean;
  @Prop({ type: String, default: () => "" }) description: string;
  @Prop({ type: Boolean, default: () => false }) hideCloseButton: boolean;
}
