import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import router from "@/routes/router";
import Auth0Lock from "auth0-lock";
import Auth0Util from "@/utils/auth";
import ConfigService from "@/services/ConfigService";
import EventService from "@/services/EventService";

export enum RoleType {
  Reader = 0,
  Writer = 1,
  Admin = 2,

}

type RolesType = {
  [P in string]: string;
};

export const Roles: RolesType = {
  [RoleType.Reader]: "Read",
  [RoleType.Writer]: "Write",
  [RoleType.Admin]: "Admin",
};

export default class AuthenticationService {
  tokenRenewalTimeout: any = null;
  private _lock: Auth0Lock = null;
  private static _instance: AuthenticationService;
  private _http: AxiosInstance;
  private _currentAuthenticatedUser: any;
  private _currentProductMenu: any[];
  private _currentClientSettings: any;

  async getLock(): Auth0Lock {
    if (this._lock === null) {
      const auth0Config = await Auth0Util.fetchConfig();
      this._lock = new Auth0Lock(
        auth0Config.cid,
        auth0Config.url,
        auth0Config.optionsAuth0Lock
      );
    }

    return this._lock;
  }

  async handleLockAuthentication() {
    const lockAuth0 = await AuthenticationService.Instance.getLock();
    lockAuth0.on("authenticated", (authResult, error) => {
      if (error) {
        console.log(error);
        return;
      }

      lockAuth0.getUserInfo(authResult.accessToken, async (error, profile) => {
        try {
          this.setSession(authResult);
          await this.loadUser();
          router.push({ path: "/home" });
        } catch (result) {
          EventService.dispatchError(result);
          AuthenticationService.Instance.redirectToLogin();
        }
      });
    });
  }

  async handleLockAuthenticationErrors() {
    const lockAuth0 = await AuthenticationService.Instance.getLock();
    lockAuth0.on("authorization_error", authResult => {
      if (router.currentRoute.fullPath !== "/login") {
        EventService.dispatchAuth0Error(authResult);
        router.push({ path: "/login" });
      }
    });
  }

  redirectToLogin() {
    if (router.currentRoute.fullPath === "/login") {
      setTimeout(() => {
        location.reload();
      }, 2000);
    } else {
      router.push({ path: "/login" });
    }
  }

  get currentAuthenticatedUser(): any {
    if (!this._currentAuthenticatedUser) {
      const stringUser = localStorage.getItem("user");

      if (stringUser == null) {
        router.push({ path: "/login" });
        return {};
      }

      this._currentAuthenticatedUser = JSON.parse(stringUser);
    }

    return this._currentAuthenticatedUser;
  }

  set currentAuthenticatedUser(value: any) {
    localStorage.setItem("user", JSON.stringify(value));
    this._currentAuthenticatedUser = value;
  }

  set currentProductMenu(value: any[]) {
    this._currentProductMenu = value;
  }

  renameProperty(oldKey: string, newKey: string, product: Record<string, any>) {
    if (product.hasOwnProperty(oldKey) && oldKey !== newKey) {
      product[newKey] = product[oldKey];
      delete product[oldKey];
    }
  }

  async getCurrentProductMenu(): Promise<any[]> {
    if (!this._currentProductMenu && this.isUserAuthenticated()) {
      const productMenu = await this.http.get(
        `${ConfigService.Instance.backEndUrl}/products`
      );

      for (const product of productMenu.data) {
        this.renameProperty("product", "type", product);
      }

      const availableProducts = productMenu.data.filter(p => p.available);
      if (productMenu.data.length !== availableProducts.length) {
        return availableProducts;
      }
      this._currentProductMenu = availableProducts;
    }
    return this._currentProductMenu;
  }

  set currentClientSettings(value: any) {
    this._currentClientSettings = value;
  }

  async getCurrentClientSettings(): Promise<any> {
    if (!this._currentClientSettings && this.isUserAuthenticated()) {
      const clientSettings = await this.http.get(
        `${ConfigService.Instance.backEndUrl}/client/settings`
      );
      this._currentClientSettings = clientSettings;
    }

    return this._currentClientSettings;
  }

  get http(): AxiosInstance {
    return (
      this._http ||
      (this._http = AuthenticationService.getAxiosInstanceWithAuthentication())
    );
  }

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = new AuthenticationService());
  }

  public isUserAuthenticated(): boolean {
    return (
      localStorage.getItem("access_token") != null &&
      localStorage.getItem("user") != null &&
      this.getTokenExpirationSecondsLeft() > 0
    );
  }

  static getAxiosInstanceWithAuthentication(
    baseUrl: string = ConfigService.Instance.backEndUrl
  ): AxiosInstance {
    const accessToken = localStorage.getItem("access_token");
    const idToken = localStorage.getItem("id_token");

    if (accessToken == undefined) {
      router.push({ path: "/login" });
    }

    const instance = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: "bearer " + accessToken,
        Payload: idToken
      }
    });
    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response) {
          if (error.response.status === 401) {
            error.response.data =
              "You must be logged in to access this ressource";
            AuthenticationService.Instance.logout().then(() =>
              AuthenticationService.Instance.redirectToLogin()
            );
          } else if (error.response.status === 403) {
            AuthenticationService.Instance.resetProductMenu();
            error.response.data = "You don't have access to this ressource";
            router.push({ path: "/home" });
          }
        }

        throw error;
      }
    );
    return instance;
  }

  async refreshProductMenu() {
    try {
      const products = await this.http.get(
        `${ConfigService.Instance.backEndUrl}/products`
      );
      this._currentProductMenu = products.data;
    } catch {}
  }

  public resetProductMenu() {
    this._currentProductMenu = null;
  }

  async joinClient(
    firstName: string,
    lastName: string,
    email: string,
    inviteId: string,
    password: string
  ): Promise<any> {
    const response = await axios.post(
      `${ConfigService.Instance.backEndUrl}/authentication/signup`,
      {
        username: email,
        password,
        email,
        firstName,
        lastName,
        inviteId
      }
    );

    localStorage.setItem("access_token", response.data.accessToken);
    localStorage.setItem("id_token", response.data.idToken);
    localStorage.setItem("expires_at", response.data.expiresAt);

    await this.loadUser();
  }

  async loadUser(): Promise<any> {
    this._http = AuthenticationService.getAxiosInstanceWithAuthentication();

    const result = await AuthenticationService.Instance.http.get(
      `${ConfigService.Instance.backEndUrl}/users/self`
    );

    AuthenticationService.Instance.currentAuthenticatedUser = result.data;
    return AuthenticationService.Instance.currentAuthenticatedUser;
  }

  async logout(): Promise<any> {
    let response;
    try {
      response = await this.http.post("/authentication/logout");
    } catch (ex) {}
    if (!response || response.status == 204) {
      this.localLogout();
      (await AuthenticationService.Instance.getLock()).logout({
        returnTo: `${ConfigService.Instance.adminAppUrl}/login`
      });
    }

    return response;
  }

  public localLogout(): void {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    clearTimeout(this.tokenRenewalTimeout);
  }

  async getUserRoles(): Promise<RoleType[]> {
    let user = AuthenticationService.Instance.currentAuthenticatedUser;

    if (user == undefined && localStorage.getItem("access_token") != null) {
      user = await this.loadUser();
    }
    return user.roles
  }

  async isMantleAdmin() {
    if(await AuthenticationService.Instance._currentAuthenticatedUser.email.endsWith("@mantle.services")){
      return true;
    }
    return false;
  }
  async isWriter(): Promise<any> {
    let user = await AuthenticationService.Instance.currentAuthenticatedUser
    if(user.roles != undefined || user.roles != null){
      return await user.roles.includes("Write") || await this.isMantleAdmin() || await this.isAdmin()
    }
    return false
  }
  async isReader(): Promise<any> {
    let user = await AuthenticationService.Instance.currentAuthenticatedUser
    if(user.roles != undefined || user.roles != null){
      return await user.roles.includes("Read") || await this.isMantleAdmin() || await this.isAdmin()
    }
    return false
  }
  async isAdmin(): Promise<any> {
    let user = await AuthenticationService.Instance.currentAuthenticatedUser
    if(user.roles != undefined || user.roles != null){
      return user.roles.includes("Admin") || this.isMantleAdmin()
    }
    return false
  }
  async isAnyRole(): Promise<any> {
    let user = AuthenticationService.Instance.currentAuthenticatedUser
    if(user.roles != undefined || user.roles != null){
      return user.roles.length > 0
    }
    return false
  }

  setSession = authResult => {
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    this._http = AuthenticationService.getAxiosInstanceWithAuthentication();

    this.scheduleRenewal();
  };

  getTokenExpirationSecondsLeft() {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return expiresAt ? expiresAt - Date.now() : 0;
  }

  async scheduleRenewal() {
    const delay = this.getTokenExpirationSecondsLeft();
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    } else {
      this.localLogout();
      (await AuthenticationService.Instance.getLock()).logout({
        returnTo: `${ConfigService.Instance.adminAppUrl}/login`
      });
    }
  }

  async renewToken() {
    const lock = await this.getLock();
    lock.checkSession({}, (err, authResult) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(authResult);
        this.loadUser();
      }
    });
  }

  setNotificationMessage(message: any) {
    localStorage.setItem("notification_message", message);
  }

  getNotificationMessage(): any {
    return localStorage.getItem("notification_message");
  }
}
