import moment from "moment";
import numeral from "numeral";
import { FileType } from "@/enums/FileType";
import { FileIcons } from "@/enums/FileIcons";

export default class DataTableService {
  static renderDate(dateFormat = "MMM Do, YYYY HH:mm") {
    return (element: any, value: any) => {
      return value != null && value !== 0
        ? moment(value).format(dateFormat)
        : "N/A";
    };
  }
  

  static renderLink(linkFunction: Function, displayFunction?: Function) {
    return (element: any, value: any) => {
      const label = displayFunction ? displayFunction(element) : value;
      const link = linkFunction(element);

      return `<a href="${link}" style="text-decoration: none !important; color: #414141;" target="_blank">${label}</a>`;
    };
  }
  
  static renderBool() {
    return (element: any, value: any) => {
      return value == 0? false: true;
    };
  }

  static renderIcon(icon: string) {
    return (element: any, value: any) => {
      return `<i class="fas ${icon}"></i>`;
    };
  }

  static renderVersion() {
    return (element: any, value: any) => {
      return `V${value}`;
    };
  }

  static renderMulti(displayFunction?: Function) {
    return (element: any, value: any[]) => {
      if(value === null){
        return ""
      }
      const func = displayFunction || ((e: any) => e);
      return `<div class="multi" title="${value
        .map(c => func(c))
        .join(", ")}"><div class="fader"></div>${value.reduce(
        (a, c) => a + "<div class='multi-item'>" + func(c) + "</div>",
        ""
      )}</div>`;
    };
  }

  static renderShortInteger() {
    return (element: any, value: any) => {
      return numeral(value).format("0,0");
    };
  }

  static renderFileIcon() {
    return (element: any, value: string) => {
      return `<i class="fas ${
        FileIcons[FileType[value as keyof typeof FileType]]
      }"></i>`;
    };
  }

  static renderTwoLevelCell(
    mainTitleFunction: Function,
    subTitleFunction: Function
  ) {
    return (element: any, value: any) => {
      const mainTitle = mainTitleFunction ? mainTitleFunction(element) : value;
      const subTitle = subTitleFunction ? subTitleFunction(element) : value;

      return `<div class="main-title">${mainTitle}</div><span class="sub-title">${subTitle}</span>`;
    };
  }

  private static wrapTooltip(content: string, tooltip: string) {
    return `<div class="tip-wrapper">${content}<div class="tooltip-content">${tooltip}</div></div>`;
  }
}
