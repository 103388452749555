
import { Component, Vue } from "vue-property-decorator";
import { Field, FieldType } from "@/components/mDataTable.vue";
import DataTableService from "@/services/DataTableService";
import AuthenticationService from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";
import mDataTable from "@/components/mDataTable.vue"
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

@Component({})
export default class CurrentBill extends Vue {
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  showCreateModal = false;
  creditBank = "";
  elements: any[] = [];
  fields: Field[] = [
    {
      title: "Id",
      prop: "id",
      width: 20,
      main: true,
      type: FieldType.HTML,
    },
    {
      title: "Credit Used",
      prop: "creditUsed",
      width: 15,
      type: FieldType.HTML,
      render: DataTableService.renderShortInteger(),
    },
    {
      title: "Credit Bank",
      prop: "creditBank",
      width: 15,
      render: DataTableService.renderShortInteger(),
    },
    {
      title: "Closed",
      prop: "closed",
      width: 10,
      render: DataTableService.renderBool(),
    },
    {
      title: "Creation Date",
      prop: "creationDate",
      width: 16,
      render: DataTableService.renderDate(),
    },
    {
      title: "End Date",
      prop: "endDate",
      width: 10,
      render: DataTableService.renderDate(),
    },
    {
      title: "Paid",
      prop: "paid",
      width: 10,
      render: DataTableService.renderBool(),
    },
  ];

  async closeBillAndOpenNew() {
    const currentBill = await AuthenticationService.Instance.http.get(
      `${ConfigService.Instance.backEndUrl}/billing`
    );
    if(currentBill.status===204){
      this.openNewBill()
      return
    }
    const resp = await AuthenticationService.Instance.http.put(
      `${ConfigService.Instance.backEndUrl}/billing/close`
    );
    if(resp.status === 200){
      this.openNewBill()
    }
  }
  async openNewBill() {
    const resp = await AuthenticationService.Instance.http.post(
      `${ConfigService.Instance.backEndUrl}/billing`,
      {
        creditBank: 1234567,
      }
    );
    (this.$refs.billTable as mDataTable).refresh();
  }

  endpoint = "/billing";
}
