import { ComponentTheme } from "@/enums/ComponentTheme";
import mToast, { ToastEvent } from "@/components/mToast.vue";

export default class EventService {
  static retrieveErrors(result: any): string[] {
    if (result && result.response) {
      const errors = result.response.data;
      const errorArray = typeof errors === "string" ? [errors] : errors;
      return errorArray.length > 0 && errorArray[0] !== ""
        ? errorArray
        : ["Sorry, an unexpected error occured"];
    }
    else if(result){
      return [result];
    } else {
      return ["Sorry, couldn't connect to the server"];
    }
  }
  static createError(message){
    const event = new CustomEvent("toast-event", {
      detail: { theme: ComponentTheme.Danger, message: message } as ToastEvent
    });
    document.dispatchEvent(event)
  }

  static dispatchError(result: any) {
    const errors = EventService.retrieveErrors(result);
    const event = new CustomEvent("toast-event", {
      detail: { theme: ComponentTheme.Danger, message: errors[0] } as ToastEvent
    });
    document.dispatchEvent(event);
  }

  static dispatchAuth0Error(result: any) {
    const error = result
      ? `Sorry, we couldn't authenticate you: ${result.errorDescription}`
      : "Sorry, an unexpected authentication error occured";
    const event = new CustomEvent("toast-event", {
      detail: { theme: ComponentTheme.Danger, message: error } as ToastEvent
    });

    document.dispatchEvent(event);
  }

  static dispatchSuccess(message: string) {
    const event = new CustomEvent("toast-event", {
      detail: {
        theme: ComponentTheme.Confirmation,
        message: message
      } as ToastEvent
    });

    document.dispatchEvent(event);
  }

  static addToastListener(mToastRef: any) {
    document.addEventListener("toast-event", (data: CustomEventInit) => {
      const mToast = mToastRef as mToast;

      if (mToast) {
        mToast.showMessage(data.detail);
      }
    });
  }

  static removeToastListener() {
    document.removeEventListener("toast-event", () => {});
  }
}
