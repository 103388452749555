import auth0 from "auth0-js";
import axios from "axios";
import yaml from "js-yaml";
import ConfigService from "@/services/ConfigService";

export default class Auth0Util {
  static async fetchConfig() {
    const configYaml = await axios.get(
      `${ConfigService.Instance.adminAppUrl}/config/server-config.yaml`
    );
    const config = yaml.safeLoad(configYaml.data);

    const cid = config.auth0Cid;
    const url = config.auth0Url;
    const optionsAuth0Lock = {
      container: "auth0",
      closable: false,
      allowSignUp: false,
      avatar: null,
      auth: {
        params: {
          scope: "openid email profile"
        },
        autoParseHash: true,
        redirect: true,
        redirectUrl: `${ConfigService.Instance.adminAppUrl}/callback`,
        responseType: "token id_token"
      },
      languageDictionary: {
        title: ""
      },
      theme: {
        labeledSubmitButton: false,
        logo: config.logoLoginUrl,
        primaryColor: "#C10606",
        authButtons: {
          connectionName: {
            displayName: "Mantle Technology",
            primaryColor: "#C10606",
            foregroundColor: "yellow",
            icon: config.logoLoginUrl
          }
        }
      }
    };

    const webAuth = new auth0.WebAuth({
      domain: url,
      clientID: cid,
      option: optionsAuth0Lock
    });

    return {
      optionsAuth0Lock: optionsAuth0Lock,
      cid: cid,
      url: url,
      webAuth: webAuth
    };
  }
}
