
import { Component, Prop, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

@Component
export default class mInfoBox extends Vue {
  componentSize = ComponentSize;
  componentTheme = ComponentTheme;

  @Prop({ type: String, default: () => "" }) title: string;
  @Prop({ type: String, default: () => "" }) content: string;
  @Prop({ type: String, default: () => ComponentTheme.Confirmation })
  type: ComponentTheme;
}
