
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class mDropZone extends Vue {
  @Prop({ type: Function, default: () => () => {} })
  onDropFileCallback: Function;
  @Prop({ type: String, default: () => "" }) inputFileId: string;
  @Prop({ type: Number, default: () => 100 }) fileSizeLimitInMB: number;
  @Prop({ type: String, default: () => "file" }) fileFormName: string;
  fileName = "";
  errorMessage = "";

  onInputChange(e: Event) {
    this.errorMessage = "";
    this.fileName = "";
    const input = document.getElementById(this.inputFileId) as HTMLInputElement;

    if (input) {
      if (input.files.length === 1) {
        const file = input.files.item(0);
        if (Math.ceil(file.size / 1000000) <= this.fileSizeLimitInMB) {
          this.setFile(file);
        } else {
          this.errorMessage = "File is too large";
        }
      } else if (input.files.length > 1) {
        this.errorMessage = "Only one file allowed";
      }
    }
  }

  public reset() {
    (document.getElementById(this.inputFileId) as HTMLInputElement).value = "";
    this.fileName = "";
    this.errorMessage = "";
  }

  public isValid() {
    return this.errorMessage === "" && this.fileName !== "";
  }

  clickHandler() {
    (document.getElementById(this.inputFileId) as HTMLInputElement).click();
  }

  dragOverHandler(e: Event) {
    e.preventDefault();
  }

  dropHandler(e: DragEvent) {
    e.preventDefault();
    if (e.dataTransfer.files.length === 1) {
      (document.getElementById(this.inputFileId) as HTMLInputElement).files =
        e.dataTransfer.files;
      this.setFile(e.dataTransfer.files.item(0));
    }
  }

  private setFile(file) {
    this.fileName = file.name;
    if (this.onDropFileCallback) {
      this.onDropFileCallback(file);
    }
  }
}
