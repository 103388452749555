
import { Component, Vue, Watch } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import VerticalMenu from "@/layout/VerticalMenu.vue";
import PageTitle from "@/layout/PageTitle.vue";
import AuthenticationService from "@/services/AuthenticationService";

@Component({
  components: { PageTitle, VerticalMenu }
})
export default class PanelContainer extends Vue {
  componentSize = ComponentSize;
  componentTheme = ComponentTheme;

  currentProductId = "";
  reloadInterval: number = null;
  progressBarInterval: number = null;
  progressBarValue = 0;
  showOverlay = false;
  overlayContentMessage = "";
  overlayContentType = "";
  overlayContentTitle = "";
  showProgressBar = false;

  onProductIdChange(productId) {
    if (this.currentProductId !== productId) {
      this.currentProductId = productId;
    }
  }

  mounted() {
    window.addEventListener("load", function() {
      AuthenticationService.Instance.scheduleRenewal();
    });
  }

  updateProgressBar() {
    const progressBarValue = document.getElementById(
      "progress-bar-value"
    ) as HTMLSpanElement;
    const progressBarText = document.getElementById(
      "progress-bar-text"
    ) as HTMLDivElement;

    if (!progressBarValue || !progressBarText) {
      return;
    }

    this.progressBarValue += 1;

    if (this.progressBarValue > 99) {
      progressBarText.setAttribute("data-label", `99%`);
      progressBarValue.setAttribute("style", `width:99%;`);
      window.clearInterval(this.progressBarInterval);
      return;
    }

    progressBarText.setAttribute("data-label", `${this.progressBarValue}%`);
    progressBarValue.setAttribute("style", `width:${this.progressBarValue}%;`);
  }

  destroyed() {
    if (this.reloadInterval) {
      window.clearInterval(this.reloadInterval);
    }
  }

  async logout() {
    try {
      await AuthenticationService.Instance.logout();
      this.$router.push("/login");
    } catch (e) {
      console.error(e);
    }
  }
}
