
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import AuthenticationService from "@/services/AuthenticationService";
import ConfigService from "@/services/ConfigService";
import EventService from "@/services/EventService";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

@Component
export default class mDataTable extends Vue {
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;

  @Prop({ type: Array, default: () => [] as Field[] }) fields: Field[];
  @Prop({ type: Array, default: () => [] as any[] }) elements: any[];
  @Prop({ type: String, default: () => "" }) endpoint: string;
  @Prop({ type: String, default: () => "" }) subTableEndpoint: string;
  @Prop({ type: Function }) onRowClick: Function;
  @Prop({
    type: Function,
    default: () => (element: any) => {
      return true;
    }
  })
  canClickRow: Function;
  @Prop({ type: Array, default: () => [] as OptionMenuItem[] })
  menuItems: OptionMenuItem[];
  @Prop({ type: Function, default: element => element.id })
  menuUniqueIdFunction: Function; // Function to select the unique id of the element
  @Prop({ type: Function, default: element => element.id })
  subTableMenuUniqueIdFunction: Function; // Function to select the unique id of the element
  @Prop({ type: Function }) selectedFunction: Function;
  @Prop({ type: String, default: () => "No data to display" })
  emptyState: string;
  @Prop({ type: String, default: () => "" }) subTableProp: string;
  @Prop({ type: String, default: () => "" }) subTableHeader: string;
  @Prop({ type: Array, default: () => [] as Field[] }) subTableFields: Field[];
  @Prop({ type: Array, default: () => [] as OptionMenuItem[] })
  subTableMenuItems: OptionMenuItem[];
  @Prop({
    type: Function,
    default: () => () => {
      return true;
    }
  })
  displaySubTable: Function;
  @Prop({ type: Boolean, default: () => false })
  autoReloadUnconfirmedEntities: boolean;
  @Prop({ type: Boolean, default: () => false }) paginate: boolean;
  @Prop({ type: Number, default: () => 10 }) pageSize: number;
  shouldAutoReload: Boolean;

  localElements = [];
  reloadInterval: any = null;
  floatType = FloatType;
  isLoading = false;
  isLoadingSubTable = false;
  selectedMenuId = "";
  selectedSubTableId = "";
  selectedRow: any = null;
  columnWidths: { [id: string]: number } = {};
  currentPage = 0;
  subTableElements: any = [];
  numberOfElements = 0;
  numberOfPages = 0;
  pagination = true;
  preferredNumberOfPages = 100;
  firstPage = 0;

  @Watch("endpoint")
  endpointListener() {
    this.refresh();
  }

  @Watch("elements")
  elementsListener() {
    this.localElements = this.elements;
  }

  destroyed() {
    if (this.reloadInterval) {
      window.clearInterval(this.reloadInterval);
    }
  }

  async mounted() {
    await this.refresh();
  }

  showMenuColumn() {
    return this.menuItems.length > 0;
  }

  showSubTableColumn() {
    return this.subTableProp !== "" && this.subTableFields.length > 0;
  }

  renderWidth(width: number, prop: string) {
    if (this.columnWidths[prop] != null) {
      return this.columnWidths[prop];
    }

    const effectiveWidth =
      99 -
      (this.showMenuColumn() ? 5 : 0) -
      (this.showSubTableColumn() ? 5 : 0);
    this.columnWidths[prop] = (width * effectiveWidth) / 99;

    return this.columnWidths[prop];
  }

  async loadPageElements(page) {
    this.currentPage = page;
    this.isLoading = true;
    await this.fetch(page);
    this.isLoading = false;
  }

  async silentRefresh() {
    if (!this.shouldAutoReload) {
      window.clearInterval(this.reloadInterval);
      return;
    }

    if (this.canAutoReload()) {
      await this.fetch(this.currentPage);
    }
  }

  async fetch(page = 0) {
    if (!page) {
      page = this.currentPage;
    }

    const getElementsResponse = await AuthenticationService.Instance.http.get(
      `${ConfigService.Instance.backEndUrl}${this.getElementsFromEndpoint(
        page,
        this.pageSize * this.preferredNumberOfPages
      )}`
    );
    if (getElementsResponse.status === 204){
      return
    }
    if (page === 0) {
      this.numberOfElements = getElementsResponse.data.length;
      this.lastPage();
    }

    if (this.paginate && getElementsResponse.data.length > this.pageSize) {
      this.localElements = getElementsResponse.data.slice(0, this.pageSize);
    } else {
      if(getElementsResponse.data.length === undefined){
        this.localElements.push(getElementsResponse.data)
      }
      else{
        this.localElements = getElementsResponse.data;
      }
    }
  }

  public async refresh() {
    if (this.reloadInterval) {
      window.clearInterval(this.reloadInterval);
    }
    this.localElements = []
    this.selectedMenuId = "";
    this.selectedSubTableId = "";
    this.selectedRow = null;
    this.columnWidths = {};
    if (this.endpoint && this.endpoint != "") {
      this.isLoading = true;
      try {
        await this.fetch(this.currentPage);

        if (
          this.autoReloadUnconfirmedEntities &&
          this.shouldAutoReload
        ) {
          this.$nextTick(function() {
            this.reloadInterval = window.setInterval(() => {
              this.silentRefresh();
            }, 10000);
          });
        }
      } catch (result) {
        EventService.dispatchError(result);
      }
      this.isLoading = false;
    }
  }

  getElementsFromEndpoint(page, limit): string {
    if (this.paginate) {
      const separator = this.endpoint.indexOf("?") !== -1 ? "&" : "?";
      return `${this.endpoint}${separator}limit=${
        page === 0 ? limit : this.pageSize
      }&offset=${page * this.pageSize}`;
    }

    return this.endpoint;
  }

  getEffectiveProp(element, field): string {
    return element.hasOwnProperty(field.prop) ? field.prop : field.backupProp;
  }

  handleOnRowClick(element: any) {
    if (this.canClickRow(element)) {
      if (this.selectedFunction) {
        this.selectedRow = this.selectedFunction(element);
      }
      if (this.onRowClick) {
        this.onRowClick(element);
      }
    }
  }

  openMenu(elementId: string) {
    this.selectedMenuId = elementId;
  }

  async openSubTable(elementId: string) {
    this.selectedSubTableId =
      this.selectedSubTableId === elementId ? "" : elementId;

    if (
      this.subTableEndpoint &&
      this.subTableEndpoint != "" &&
      this.selectedSubTableId &&
      this.selectedSubTableId != ""
    ) {
      this.isLoadingSubTable = true;

      try {
        const res = await AuthenticationService.Instance.http.get(
          `${this.subTableEndpoint}/${this.selectedSubTableId}`
        );
        this.subTableElements = res.data;
      } catch (result) {
        EventService.dispatchError(result);
      }

      this.isLoadingSubTable = false;
    }
  }

  public getSubTableOpenId(): string {
    return this.selectedSubTableId;
  }

  public canAutoReload(): boolean {
    return (
      this.selectedMenuId === "" &&
      this.selectedRow == null &&
      this.selectedSubTableId === "" &&
      this.isLoading === false &&
      this.shouldAutoReload === true
    );
  }

  hideRowMenu() {
    this.selectedMenuId = "";
  }

  isRowSelected(element: any) {
    return (
      !!this.selectedFunction &&
      this.selectedFunction(element) === this.selectedRow
    );
  }

  lastPage() {
    this.numberOfPages = Math.ceil(this.numberOfElements / this.pageSize);
  }
}

export interface Field {
  title: string;
  prop: string;
  backupProp?: string;
  width?: number;
  float?: FloatType;
  type?: FieldType;
  render?: Function;
  main?: boolean;
}

export interface OptionMenuItem {
  title: string;
  icon: string;
  onClick: Function;
  disabled?: Function;
}

export enum FieldType {
  HTML = "HTML",
  Icon = "icon"
}

export enum FloatType {
  Center = "center",
  Left = "left",
  Right = "right"
}
