
import { Component, Prop, Vue } from "vue-property-decorator";

import mDataTable, {
  Field,
  FieldType,
  FloatType,
  OptionMenuItem
} from "@/components/mDataTable.vue";
import InviteModal from "./components/InviteModal.vue";

import { ComponentSize } from "@/enums/ComponentSize";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { InvitationStatus } from "@/enums/InvitationStatus";

import ConfigService from "@/services/ConfigService";
import EventService from "@/services/EventService";
import DataTableService from "@/services/DataTableService";
import AuthenticationService from "@/services/AuthenticationService";

@Component({
  components: {
    InviteModal
  }
})
export default class InviteList extends Vue {
  @Prop({ type: String, default: () => "" }) clientId: string;

  fields: Field[] = [
    {
      title: "",
      prop: "icon",
      width: 5,
      type: FieldType.HTML,
      render: DataTableService.renderIcon("fa-envelope-open"),
      float: FloatType.Center
    },
    { title: "Email", prop: "newUserEmail", width: 30, main: true },
    { title: "Status", prop: "status", width: 10 },
    {
      title: "Creation Date",
      prop: "creationDateTime",
      width: 20,
      render: DataTableService.renderDate()
    }
  ];

  menuItems: OptionMenuItem[] = [
    {
      title: "Copy invite link",
      icon: "fa-link",
      onClick: this.onCopyLinkClick,
      disabled: this.isInviteAccepted
    },
    {
      title: "Delete",
      icon: "fa-trash",
      onClick: this.onDeleteInviteClick,
      disabled: this.isInvitePending
    }
  ];

  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  endpoint = "";
  showInviteUser = false;
  showDeleteInvite = false;
  showCopyLink = false;
  inviteIdToDelete = "";
  inviteEmailToDelete = "";
  copyLink = "";

  mounted() {
    this.endpoint = this.clientId
      ? `/clients/${this.clientId}/invitations`
      : "/invitations";
  }

  isInvitePending(element: any) {
    return element.status === InvitationStatus[InvitationStatus.Pending];
  }

  isInviteAccepted(element: any) {
    return element.status === InvitationStatus[InvitationStatus.Accepted];
  }

  onInviteUserClick() {
    this.showInviteUser = true;
  }

  onCopyLinkClick(element: any) {
    this.copyLink = `${ConfigService.Instance.adminAppUrl}/signup/${element.id}`;
    this.showCopyLink = true;
  }

  onDeleteInviteClick(element: any) {
    this.inviteIdToDelete = element.id;
    this.inviteEmailToDelete = element.newUserEmail;
    this.showDeleteInvite = true;
  }

  onCloseInviteModal() {
    this.showInviteUser = false;
  }

  onCloseDeleteModal() {
    this.showDeleteInvite = false;
    this.inviteIdToDelete = "";
    this.inviteEmailToDelete = "";
  }

  onCloseCopyLinkModal() {
    this.showCopyLink = false;
    this.copyLink = "";
  }

  async onCopyLinkConfirm() {
    (document.getElementById("copyLink") as HTMLInputElement).select();
    document.execCommand("copy");
    setTimeout(this.onCloseCopyLinkModal, 500);
    EventService.dispatchSuccess("Invite link successfully copied!");
  }

  async onDeleteInviteConfirm() {
    const route = this.clientId
      ? `/clients/${this.clientId}/invitations/${this.inviteIdToDelete}`
      : `/invitations/${this.inviteIdToDelete}`;
    const response = await AuthenticationService.Instance.http.delete(
      `${ConfigService.Instance.backEndUrl}${route}`
    );
    this.onCloseDeleteModal();
    (this.$refs.inviteTable as mDataTable).refresh();
  }

  async onInviteUserConfirm() {
    const inviteModal = this.$refs.inviteModal as InviteModal;
    const route = this.clientId
      ? `/clients/${this.clientId}/invitations`
      : "/invitations";

    const result = await AuthenticationService.Instance.http.post(
      `${ConfigService.Instance.backEndUrl}${route}`,
      {
        roles: inviteModal.getSelectedRoles(),
        email: inviteModal.getEmail()
      }
    );

    this.onCloseInviteModal();
    inviteModal.reset();
    (this.$refs.inviteTable as mDataTable).refresh();
  }
}
