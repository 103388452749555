
import { Component, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import ConfigService from "@/services/ConfigService";
import EventService from "@/services/EventService";
import AuthenticationService from "@/services/AuthenticationService";

@Component
export default class ApiKeyPage extends Vue {
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  apiKey: string = null;
  isLoading = true;

  mounted() {
    this.loadApiKey();
  }

  async loadApiKey() {
    this.isLoading = true;
    try {
      const response = await AuthenticationService.Instance.http.get(
        `${ConfigService.Instance.backEndUrl}/apikeys/self`
      );
      this.apiKey = response.data;
    } catch (result) {
      EventService.dispatchError(result);
      this.apiKey = null;
    }
    this.isLoading = false;
  }

  async onCopyKeyClick() {
    (document.getElementById("copyLink") as HTMLInputElement).select();
    document.execCommand("copy");
    EventService.dispatchSuccess("API Key successfully copied!");
  }
}
