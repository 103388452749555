
import { Component, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import AuthenticationService from "@/services/AuthenticationService";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";

// TODO: put this in it's own file
@Component({
  filters: {
    capitalize: (value: string) =>
      !value ? value : value.charAt(0).toUpperCase() + value.slice(1)
  }
})
export default class PageTitle extends Vue {
  breadcrumbs: Breadcrumb[] = [];
  effectiveBreadcrumbs: Breadcrumb[] = [];
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  authenticatedUser = "";
  showHelpSection = false;

  @Watch("$route")
  onRouteChange(to: Route) {
    this.setTitleAndBreadcrumb(to);
  }

  @Watch("breadcrumbs")
  onBreadcrumbsChanged() {
    this.effectiveBreadcrumbs = this.getBreadCrumbs();
  }

  getBreadCrumbs() {
    if (window.innerWidth > 481 || this.breadcrumbs.length < 3) {
      return this.breadcrumbs;
    }

    const ellipsisIndex = this.breadcrumbs.length - 3;

    return [
      {
        title: "...",
        path: this.breadcrumbs[ellipsisIndex].path,
        isNavigable: this.breadcrumbs[ellipsisIndex].isNavigable
      } as Breadcrumb
    ].concat(this.breadcrumbs.slice(-2));
  }

  onHelpClick() {
    this.showHelpSection = true;
  }

  onHelpBackDropClick() {
    this.showHelpSection = false;
  }

  mounted() {
    this.setTitleAndBreadcrumb(this.$route);
    const user = AuthenticationService.Instance.currentAuthenticatedUser;
    if (user) {
      this.authenticatedUser =
        AuthenticationService.Instance.currentAuthenticatedUser.email;
    }
    document.addEventListener(
      "breadcrumb-event",
      (data: CustomEventInit) => (this.breadcrumbs = data.detail.breadcrumbs)
    );
  }

  destroyed() {
    document.removeEventListener("breadcrumb-event", () => {});
  }

  async logout() {
    try {
      await AuthenticationService.Instance.logout();
      this.$router.push("/login");
    } catch (e) {
      console.error(e);
    }
  }

  setTitleAndBreadcrumb(route: Route) {
    if (!route.meta.isCustomBreadcrumb) {
      this.breadcrumbs = route.matched
        .filter(x => x.name !== "panel")
        .map(x => new Breadcrumb(x.meta.title, x.path, x.meta.isNavigable));
    }
  }
}

export class Breadcrumb {
  title: string;
  path: string;
  isNavigable: boolean;

  constructor(title: string, path: string, isNavigable = true) {
    this.title = title;
    this.path = path;
    this.isNavigable = isNavigable;
  }
}
