
import { Component, Prop, Vue } from "vue-property-decorator";
import mDataTable, {
  Field,
  FieldType,
  FloatType,
  OptionMenuItem
} from "@/components/mDataTable.vue";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import DataTableService from "@/services/DataTableService";
import ConfigService from "@/services/ConfigService";
import mUserSelector from "@/components/mUserSelector.vue";
import AuthenticationService from "@/services/AuthenticationService";

@Component
export default class ApiKeyList extends Vue {
  fields: Field[] = [
    {
      title: "",
      prop: "icon",
      width: 10,
      type: FieldType.HTML,
      render: DataTableService.renderIcon("fa-key"),
      float: FloatType.Center
    },
    { title: "User Email",
      prop: "userEmail",
      type: FieldType.HTML,
      width: 65, main: true,
     render: DataTableService.renderTwoLevelCell(
        (element: any) => element.userEmail,
        (element: any) => element.name? element.name: ""
      )
    },
    {
      title: "Creation Date",
      prop: "creationDate",
      width: 25,
      render: DataTableService.renderDate()
    }
  ];
  menuItems: OptionMenuItem[] = [
    { title: "Delete", icon: "fa-trash", onClick: this.onDeleteApiKeyClick }
  ];
  componentTheme = ComponentTheme;
  componentSize = ComponentSize;
  endpoint = "/apikeys?sa=false";
  showCreateModal = false;
  showDeleteModal = false;
  userEmailToDelete = "";
  userIdToDelete = "";

  onCreateApiKeyClick() {
    this.showCreateModal = true;
  }

  onDeleteApiKeyClick(element: any) {
    this.userEmailToDelete = element.userEmail;
    this.userIdToDelete = element.userId;
    this.showDeleteModal = true;
  }

  onCloseCreateModal() {
    this.showCreateModal = false;
    const userId = (this.$refs
      .userSelector as mUserSelector).reset();
  }

  onCloseDeleteModal() {
    this.showDeleteModal = false;
  }

  async onDeleteApiKeyConfirm() {

    await AuthenticationService.Instance.http.delete(
      `${ConfigService.Instance.backEndUrl}/apikeys/${this.userIdToDelete}`
    );
    this.onCloseDeleteModal();
    (this.$refs.apiKeyTable as mDataTable).refresh();
  }

  async onCreateApiKeyConfirm() {
    const userId = (this.$refs
      .userSelector as mUserSelector).getSelectedUserId();
    await AuthenticationService.Instance.http.post(
      `${ConfigService.Instance.backEndUrl}/apikeys`,
      { userId: userId }
    );
    this.onCloseCreateModal();
    (this.$refs.apiKeyTable as mDataTable).refresh();
  }
}
