import { Route, RouteConfig } from "vue-router";
import ClientList from "@/views/Admin/ClientList.vue";
import ClientInvitePage from "@/views/Admin/ClientInvitePage.vue";
import ServiceAccountList from "@/views/Settings/ServiceAccountList.vue"
import ServiceAccountApiKeysList from "@/views/Settings/ServiceAccountApiKeysList.vue"

export const AdminRoutes: RouteConfig[] = [
  {
    path: "/admin/clients",
    name: "client-list",
    component: ClientList,
    meta: {
      role: "Admin",
      title: "Clients"
    }
  },
  {
    path: "/admin/serviceaccount",
    name: "Service-Account-list",
    component: ServiceAccountList,
    meta: {
      role: "Admin",
      title: "Service Account"
    }
  },
  {
    path: "/admin/clients/:clientId/invites",
    name: "client-invite-list",
    component: ClientInvitePage,
    meta: {
      role: "Admin",
      title: "Client Invite List",
      isCustomBreadcrumb: true
    }
  },
  
];
