
import { Component, Vue } from "vue-property-decorator";
import { ProductOptions } from "@/enums/ProductOptions"
import AuthenticationService from "@/services/AuthenticationService";

@Component
export default class HomePage extends Vue {
  roles = <any>[]
  productOptions =  <any> [];

  async created() {
    this.roles = await AuthenticationService.Instance.getUserRoles();
    this.productOptions.push(ProductOptions.Settings)
    
    if(this.roles != null && this.roles.length > 0){
      this.productOptions.push(ProductOptions.Gateway)
    }
    if(this.roles != null && this.roles.includes("Admin")){
      this.productOptions = ProductOptions;
    }
  }
}
