
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import EventService from "@/services/EventService";

@Component
export default class mAsyncModal extends Vue {
  componentSize = ComponentSize;
  componentTheme = ComponentTheme;

  @Prop({ type: Boolean, default: () => false }) display: boolean;
  @Prop({ type: String, default: () => "" }) tooltip: string;
  @Prop({ type: Function, default: () => async () => {} }) onConfirm: Function;
  @Prop({ type: Function, default: () => () => {} }) onCancel: Function;
  @Prop({ type: String, default: () => "" }) title: string;
  @Prop({ type: String, default: () => "Confirm" }) confirmButtonTitle: string;
  @Prop({ type: String, default: () => "fa-check" }) confirmButtonIcon: string;
  @Prop({ type: String, default: () => ComponentTheme.Confirmation })
  confirmButtonType: ComponentTheme;
  @Prop({ type: String, default: () => "" }) description: string;
  @Prop({ type: String, default: () => "Operation succeeded" })
  confirmationMessage: string;
  @Prop({ type: String, default: () => null }) focusId: string;
  @Prop({ type: Function, default: () => false }) preventKeyShortcuts: Function;
  @Prop({ type: Boolean, default: () => true }) shouldDispatchSuccess: boolean;
  @Prop({ type: Object, default: () => null }) customButton: CustomButton;

  loading = false;
  errors: string[] = [];
  results: string[] = [];

  async onConfirmClick() {
    if (!this.loading) {
      this.loading = true;
      this.errors = [];
      this.results = [];

      try {
        await this.onConfirm();
        if (this.shouldDispatchSuccess) {
          EventService.dispatchSuccess(this.confirmationMessage);
        }
      } catch (result) {
        this.errors = EventService.retrieveErrors(result);
      }

      this.loading = false;
    }
  }

  async onCancelClick() {
    if (!this.loading) {
      this.errors = [];
      this.results = [];
      this.onCancel();
    }
  }

  async onCustomButtonClick() {
    if (!this.loading) {
      this.loading = true;
      this.errors = [];
      this.results = [];

      try {
        const response = await this.customButton.onClick();

        if (this.shouldDispatchSuccess && response.message) {
          EventService.dispatchSuccess(this.confirmationMessage);
          this.results.push(response.message);
        }
      } catch (result) {
        this.errors = EventService.retrieveErrors(result);
      }

      this.loading = false;
    }
  }

  mounted() {
    document.addEventListener("keyup", event => {
      if (this.display && !this.preventKeyShortcuts()) {
        if (event.keyCode == 13) {
          this.onConfirmClick();
        } else if (event.keyCode === 27) {
          this.onCancelClick();
        }
      }
    });
  }

  destroyed() {
    document.removeEventListener("keyup", () => {});
  }

  @Watch("display")
  displayListener() {
    if (this.display && !this.loading && this.focusId) {
      this.$nextTick(function() {
        const input = document.getElementById(this.focusId);
        if (input) {
          input.focus();
        }
      });
    }
  }
}

export interface CustomButton {
  title: string;
  iconLeft: string;
  onClick: Function;
}
