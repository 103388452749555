import PageNotFound from "@/views/PageNotFound.vue";
import PageForbidden from "@/views/PageForbidden.vue";
import Login from "@/views/Login/Login.vue";
import Callback from "@/views/Login/Callback.vue";
import JoinClient from "@/views/Login/JoinClient.vue";

import { RouteConfig } from "vue-router";

export const BaseRoutes: RouteConfig[] = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/callback",
    name: "callback",
    component: Callback
  },
  {
    path: "/signup/:inviteId",
    name: "join",
    component: JoinClient
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: PageForbidden
  },
  {
    path: "*",
    name: "notfound",
    component: PageNotFound
  }
];
