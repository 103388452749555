
import { Component, Prop, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import mInfoBox from "@/components/mInfoBox.vue";
import UtilsService from "@/services/UtilsService";

@Component
export default class mToggleButton extends Vue {
  componentSize = ComponentSize;
  componentTheme = ComponentTheme;
  value = false;

  @Prop({ type: String, default: () => "" }) label: string;
  @Prop({ type: String, default: () => "Off" }) offLabel: string;
  @Prop({ type: String, default: () => "On" }) onLabel: string;
  @Prop({ type: String, default: () => UtilsService.generateGuid() })
  formId: string;
  @Prop({ type: Function, default: () => () => {} }) onValueChanged: Function;
  @Prop({ type: Boolean, default: () => false }) defaultValue: boolean;
  @Prop({ type: String, default: () => ComponentTheme.Info })
  type: ComponentTheme;
  @Prop({ type: String, default: () => ComponentSize.Small })
  size: ComponentSize;
  @Prop({ type: Boolean, default: () => false }) disabled: boolean;
  @Prop({ type: String, default: () => "" }) offInfoBoxContent: string;
  @Prop({ type: String, default: () => "" }) onInfoBoxContent: string;

  handleOnChange(event) {
    if (!this.disabled) {
      this.onValueChanged(this.value);
    }
  }

  mounted() {
    this.reset();
  }

  public reset() {
    this.value = this.defaultValue;
  }

  public getValue(): boolean {
    return this.value;
  }
}
