import { render, staticRenderFns } from "./mToast.vue?vue&type=template&id=667b8ecb&scoped=true"
import script from "./mToast.vue?vue&type=script&lang=ts"
export * from "./mToast.vue?vue&type=script&lang=ts"
import style0 from "./mToast.vue?vue&type=style&index=0&id=667b8ecb&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667b8ecb",
  null
  
)

export default component.exports