
import { Component, Vue } from "vue-property-decorator";
import ConfigService from "@/services/ConfigService";
import AuthenticationService from "@/services/AuthenticationService";
import { Breadcrumb } from "@/layout/PageTitle.vue";
import InviteList from "../Settings/InviteList.vue";

@Component({
  components: {
    InviteList
  }
})
export default class ClientInvitePage extends Vue {
  clientName = "";
  clientId = ""

  async mounted() {
    if (this.$route.params.clientId) {
      const response = await AuthenticationService.Instance.http.get(
        `${ConfigService.Instance.backEndUrl}/clients/${this.$route.params.clientId}`
      );
      this.clientName = response.data.name;
      this.$route.meta.breadcrumb = [
        new Breadcrumb("Clients", "/admin/clients"),
        new Breadcrumb(this.clientName, "", false),
        new Breadcrumb(
          "Invite",
          `/admin/clients/${this.$route.params.clientId}/invites`
        )
      ];

      const event = new CustomEvent("breadcrumb-event", {
        detail: {
          breadcrumbs: this.$route.meta.breadcrumb
        }
      });
      document.dispatchEvent(event);
    }
  }
}
