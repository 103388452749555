import { RouteConfig } from "vue-router";
import BillingList from "@/views/Billing/BillingList.vue";
import CurrentBill from "@/views/Billing/CurrentBill.vue";
import HomePage from "@/views/Home/HomePage.vue";

export const BillingRoutes: RouteConfig[] = [
  {
    path: "/billing",
    name: "billing",
    component: BillingList,
    meta: {
      role: "Admin",
      title: "Billing"
    }
  },
 {
  path: "billing/all",
  name: "all bills",
  component: BillingList,
  meta: {
    role: "Admin",
    title: "All bills"
  }
 },
 {
  path: "billing/current",
  name: "Current bills",
  component: CurrentBill,
  meta: {
    role: "Admin",
    title: "All bills"
  }
}
];
