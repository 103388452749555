
import { Component, Prop, Vue } from "vue-property-decorator";
import { ComponentTheme } from "@/enums/ComponentTheme";
import { ComponentSize } from "@/enums/ComponentSize";
import UtilsService from "@/services/UtilsService";

@Component
export default class mOverlay extends Vue {
  componentSize = ComponentSize;
  componentTheme = ComponentTheme;

  @Prop({ type: Function, default: () => () => {} }) onClick: Function;
}
